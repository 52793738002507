export enum AppRoutesList {
  demo = 'demo',
  notFound = '404',
  forbidden = '403',
  login = '',
  resetPassword = 'reset-password',
  setNewPassword = 'reset-password/set',
  registration = 'registration',
  verifyEmail = 'verify-email/set/:token',
}
